<template>
  <div v-if="showUserSelection">
    <q-card class="bg-white">
      <q-card-section>
        <div class="row q-col-gutter-sm">
          <div v-if="showRequesterField" class="col">
            <m-user-lookup
              v-model="selectedRequester"
              :label="$t('requester')"
            />
          </div>
          <div v-if="!hideTraveller" class="col">
            <m-user-lookup
              ref="travellerLookup"
              v-model="selectedTraveller"
              :disable="readOnlyTraveller"
              :organisation="filter.organisation"
              :label="showRequesterField ? $tc('traveller') : $t('concierge.who_for')"
            />
          </div>
        </div>
      </q-card-section>
      <q-card-section v-if="people.requester && !isSelfBooker" class="validation">
        The selected requester is not able to book or pay for their own journey. Please select an appropriate Middle Office user from the same organisation.
      </q-card-section>
    </q-card>
  </div>
</template>
<script>
import authentication from 'mixins/authentication'
import { MUserLookup } from 'components'
import { mapGetters } from 'vuex'

export default {
  name: 'MUnifiedUsers',
  components: { MUserLookup },
  mixins: [ authentication ],
  props: {
    value: Object,
    hideTraveller: {
      type: Boolean,
      default: false
    },
    hideRequester: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      people: {
        traveller: this.value.traveller,
        requester: this.value.requester
      },
      isSelfBooker: true
    }
  },
  computed: {
    ...mapGetters({ userLookup: 'userLookup' }),
    filter () {
      var params = {
        organisation: null,
        maxRole: null
      }
      var requester = this.selectedRequester

      if (requester && requester.organisation && requester.organisation !== 'Individual') {
        params.organisation = requester.organisation
      }
      if (requester && requester.roleWeight) {
        params.maxRole = requester.roleWeight
      }

      return params
    },
    showRequesterField () {
      return (this.hideRequester ? false : this.hasPermission('can.book.for.anyone'))
    },
    showUserSelection () {
      return (this.hasPermission('can.book.for.org.members') && !this.hideTraveller) || this.hasPermission('can.book.for.anyone')
    },
    selectedRequester: {
      get () {
        return this.people.requester
      },
      set (val) {
        const travellerLookup = this.$refs.travellerLookup
        if (travellerLookup) travellerLookup.clearCurrentSelection()
        this.people.requester = val
        this.isSelfBooker = val?.permissions?.includes('payments.card') || val?.permissions?.includes('payments.paypal') || val?.permissions?.includes('payments.account')
        if (val && val.organisation === 'Individual') {
          this.selectedTraveller = val
        }
      }
    },
    selectedTraveller: {
      get () {
        return this.people.traveller
      },
      set (val) {
        this.people.traveller = val
      }
    },
    isRequesterMember () {
      var requester = this.selectedRequester
      return requester && requester.value && requester.organisation !== 'Individual'
    },
    readOnlyTraveller () {
      return this.showRequesterField && !this.isRequesterMember
    }
  },
  watch: {
    people: {
      deep: true,
      handler (params) {
        this.$emit('input', params)
      }
    }
  },
  created () {
    if (this.hasRole('middleoffice')) {
      this.people.requester = this.userLookup
      this.people.traveller = this.userLookup
    } else if (!this.hasRole(['middleoffice', 'backoffice', 'admin'])) {
      this.people.requester = this.userLookup
      this.people.traveller = this.userLookup
    }
  },
  activated () {
    if (this.hasRole('middleoffice')) {
      this.people.requester = this.userLookup
      this.people.traveller = this.userLookup
    } else if (!this.hasRole(['middleoffice', 'backoffice', 'admin'])) {
      this.people.requester = this.userLookup
      this.people.traveller = this.userLookup
    }
  }
}
</script>

<style lang="stylus" scoped>
.q-card
  max-width 600px

.validation
  color red
  font-size 90%
</style>
